import { createStore } from "vuex";
import api from "../components/http/api";
import msg from "../components/http/msgbox";
import { dist2 } from "@/components/Game/GameList";
export default createStore({
  state: {
    isLoading: false,
    islang: false,
    isPreImg: true,
    isann: false,
    pop: {
      isLoading: false,
      data: {
        action: "",
        msg: "",
        class: "",
        type: "",
      },
    },
    parameter: {
      action: "",
      body: {},
    },
    service: {
      qq: "",
      tel: "",
    },
    list: [],
    balance: "",
    logo: "",
    flag_nav: false,
  },
  mutations: {
    Loading(state, payload) {
      state.isLoading = payload;
    },
    Lang(state, payload) {
      state.islang = payload;
    },
    PreImg(state, payload) {
      state.isPreImg = payload;
    },
    isann(state, payload) {
      state.isann = payload;
    },
    Pop(state, payload) {
      state.pop = payload;
    },
    Service(state, payload) {
      state.service.qq = payload;
    },
    GetIP() {
      api.getIP().then((res) => {
        localStorage.setItem("ip", res);
      });
    },
    GetList(state, payload) {
      state.list = payload;
    },
    Getbalance(state, payload) {
      state.balance = payload;
    },
    GetLogo(state, payload) {
      state.logo = payload.logo == "" ? require("@/assets/logo.png") : payload.logo;
    },
    flagnav(state, payload) {
      state.flag_nav = payload;
    },
  },
  actions: {
    setGetIP(context) {
      context.commit("GetIP");
    },
    setLoading(context, payload) {
      context.commit("Loading", payload);
    },
    setLang(context, payload) {
      context.commit("Lang", payload);
    },
    showPop(context, payload) {
      context.commit("Pop", payload);
    },
    getpost(context, parameter) {
      return new Promise((resolve) => {
        api.apiPost(parameter.action, parameter.body).then((res) => {
          if (msg.resPonseStatus(res)) {
            if (res.status == "fail") {
              msg.API_popmsg(res.msg, "", "error");
            } else if (res.status == "relogin") {
              msg.API_popmsg(res.msg, "relogin", "error");
            } else {
              resolve(res);
            }
          }
        });
      });
    },
    getService(context) {
      this.dispatch("getpost", { action: "service" }).then((res) => {
        context.commit("Service", res);
      });
    },
    // getList(context, parameter) {
    //   context.commit("GetList", []);
    //   this.dispatch("getpost", parameter).then((res) => {
    //     try {
    //       context.commit("GetList", JSON.parse(res)["data"]);
    //     } catch {
    //       context.commit("GetList", JSON.parse(res)["list"]);
    //     }
    //   });
    // },
    getGameList(context, parameter) {
      if (parameter.action != "") {
        if (
          localStorage.getItem(parameter.body.gametype + "_gamelist") == null
        ) {
          this.dispatch("getpost", parameter).then((res) => {
            localStorage.setItem(
              parameter.body.gametype + "_gamelist",
              JSON.stringify(res["list"])
            );
            var list = res["list"];
            list.forEach((v) => {
              if (
                // v["gamekind"] == "casino" ||
                v["gamekind"] == "sport" ||
                v["gametype"] == "IGS"
              ) {
                v["iconName"] = dist2.filter((i) => {
                  return (
                    i["gameType"] == v["gametype"] &&
                    v["gamekind"] == i["gameKind"]
                  );
                })[0]["iconName"];
              } else {
                v["iconName"] = "http://gameweb.xkzkji.com/" + v["image"];
              }
              v["gameId"] = v["gameid"];
              v["gameType"] = v["gametype"];
              v["gameKind"] = v["gamekind"];
              v["gameName"] = v["gamename"];
            });
            context.commit("GetList", list);
          });
        } else {
          list = JSON.parse(
            localStorage.getItem(parameter.body.gametype + "_gamelist")
          );
          list.forEach((v) => {
            if (
              // v["gamekind"] == "casino" ||
              v["gamekind"] == "sport" ||
              v["gametype"] == "IGS"
            ) {
              v["iconName"] = dist2.filter((i) => {
                return (
                  i["gameType"] == v["gametype"] &&
                  v["gamekind"] == i["gameKind"]
                );
              })[0]["iconName"];
            } else {
              v["iconName"] = "http://gameweb.xkzkji.com/" + v["image"];
            }
            v["gameId"] = v["gameid"];
            v["gameType"] = v["gametype"];
            v["gameKind"] = v["gamekind"];
            v["gameName"] = v["gamename"];
          });
          context.commit("GetList", list);
        }
      } else {
        var list = [];
        list = dist2.filter((v) => {
          if (parameter.body.gametype === "all") {
            return v;
          } else {
            return (
              String(v["Gamestype"]).toLowerCase() == parameter.body.gametype
            );
          }
        });
      }
      context.commit("GetList", list);
    },
    getLogo(context) {
      this.dispatch("getpost", {
        action: "Set_Logo",
        body: {
          regdomain: document.location.origin,
        },
      }).then((res) => {
        context.commit("GetLogo", res);
        if (res.status == "no match") {
          document.title = "GAMEWEB | The Website to Play Games Online. Earn Money and Have Fund.";
          let s = document.createElement('meta');
          s.setAttribute('name', 'description');
          s.setAttribute('content', 'Gameweb provides most popular games. 100% Fun, legal, trusted to play. Can easily earn money and exchange gift. By building your game team, share to friend to earn cash easily. Become a gameweber by just sharing games can earn money online with ease.');
          document.head.appendChild(s);
          s = document.createElement('meta');
          s.setAttribute('name', 'keywords');
          s.setAttribute('content', 'GAMEWEB, FREE GAME , CASINO GAME, GAME APP , MAKE MONEY ONLINE. SLOT Game.');
          document.head.appendChild(s);
          const link = document.querySelector("[rel='icon']");
          link.setAttribute("href", 'favicon.ico');
        }
        else {
          document.title = res.cname + " | The Website to Play Games Online. Earn Money and Have Fund.";
          let s = document.createElement('meta');
          s.setAttribute('name', 'description');
          s.setAttribute('content', res.cname + ' provides most popular games. 100% Fun, legal, trusted to play. Can easily earn money and exchange gift. By building your game team, share to friend to earn cash easily. Become a gameweber by just sharing games can earn money online with ease.');
          document.head.appendChild(s);
          s = document.createElement('meta');
          s.setAttribute('name', 'keywords');
          s.setAttribute('content', res.cname + ', FREE GAME , CASINO GAME, GAME APP , MAKE MONEY ONLINE. SLOT Game.');
          document.head.appendChild(s);
          const link = document.querySelector("[rel='icon']");
          link.setAttribute("href", res.logo);
        }
      });
    },
  },
  getters: {
    pop: (state) => {
      return state.pop;
    },
    islang: (state) => {
      return state.islang;
    },
    isLoading: (state) => {
      return state.isLoading;
    },
    isPreImg: (state) => {
      return state.isPreImg;
    },
    isann: (state) => {
      return state.isann;
    },
    service: (state) => {
      return state.service;
    },
    list: (state) => {
      return state.list;
    },
    logo: (state) => {
      return state.logo;
    },
    flag_nav: (state) => {
      return state.flag_nav;
    },
  },
});
