<template>
  <div class="wc-PageView wc_bank">
    <div class="wc-PageView_NavigationMenu">
      <div
        class="nm-NavigationMenuModule-footerdisplayed nm-NavigationMenuModule nm-NavigationMenuModule-wide safe_out_div"
        style="width: 400px"
      >
        <div class="nm-MenuHeader">
          <div class="nm-MenuHeader_Text">{{ $t("safe_text1") }}</div>
        </div>
        <section data-section="sba-balances">
          <div class="buttons-row pt-2">
            <div
              class="button-links btn-deposit rounded-5"
              :class="id == 's_deposit' ? 'safe_btn_hover' : ''"
              @click="id = 's_deposit'"
            >
              <div class="fn-btn-deposit">{{ $t("deposit") }}</div>
            </div>

            <div
              class="button-links btn-withdraw rounded-5"
              :class="id == 's_withdraw' ? 'safe_btn_hover' : ''"
              @click="id = 's_withdraw'"
            >
              <div class="fn-btn-withdrawal">{{ $t("withdrawl") }}</div>
            </div>
          </div>
        </section>

        <div class="wmeTextBox mt-3" v-show="id == 's_deposit'">
          <div class="mb-3">
            <div class="mb-1">{{ $t("safe_text2") }}</div>
            <div class="d-flex align-items-center">
              <input type="text" class="w-75" />
              <div
                class="w-25 text-center max_div"
                style="background-color: #ccc"
              >
                {{ $t("safe_text4") }}
              </div>
            </div>
          </div>

          <div class="col-12 text-center">
            <button
              type="button"
              class="border-0 text-white bg_green_btn p-2 w-50 mt-3"
            >
              {{ $t("safe_text3").toUpperCase() }}
            </button>
          </div>
        </div>

        <div class="wmeTextBox mt-3" v-show="id == 's_withdraw'">
          <div class="mb-3">
            <div class="mb-1">{{ $t("safe_text2") }}</div>
            <div class="d-flex align-items-center">
              <input type="text" class="w-75" />
              <div
                class="w-25 text-center max_div"
                style="background-color: #ccc"
              >
                {{ $t("safe_text4") }}
              </div>
            </div>
          </div>

          <div class="col-12 text-center">
            <button
              type="button"
              class="border-0 text-white bg_green_btn p-2 w-50 mt-3"
            >
              {{ $t("safe_text5").toUpperCase() }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {},
  components: {},
  data() {
    return {
      id: "s_deposit",
    };
  },
  created() {},
  methods: {},
};
</script>
