<template>
  <div
    class="row position-fixed border-bottom bg-white top-0 w-100 d-xl-none d-flex p-2"
    style="padding: 8px 0 0 0; z-index: 99"
    @click="go_home()"
  >
    <p
      v-if="code != null"
      class="col-6 text_gray fw-bold mb-0"
      style="font-size: 1.2rem"
    >
      {{ userid }}
    </p>
    <img
      v-else
      :src="logo"
      style="width: 38%; cursor: pointer"
      :title="$t('go_home')"
    />
  </div>
</template>
<script>
import Cookies from "js-cookie";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "maintop2",
  data() {
    return {
      code: Cookies.get("code"),
      userid: "GAMEWEB",
    };
  },
  components: {},
  computed: {
    ...mapGetters(["logo"]),
  },
  methods: {
    ...mapActions(["getLogo"]),
    go_home: function () {
      if (this.$route.name == "Home") {
        window.location.reload();
      } else this.$router.push("/");
    },
  },
  created() {
    if (this.code != null) {
      this.userid = localStorage.getItem("uid");
    } else {
      this.userid =
        localStorage.getItem("introducer") == null ||
        localStorage.getItem("introducer") == ""
          ? "GAMEWEB"
          : localStorage.getItem("introducer");
    }
    this.getLogo();
  },
  watch: {},
};
</script>
