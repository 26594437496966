<template>
  <div
    class="col-12 text-end"
    v-if="code != null"
    :class="
      whopage == 'homepc'
        ? 'mt-3'
        : whopage == 'homemobi'
        ? 'mb-3 pe-0'
        : 'pe-0 pt-3'
    "
  >
    <img
      src="@/assets/img/main/diamond.png"
      class="col-4 balance_img me-2"
      style="width: 30px; height: 30px; cursor: pointer"
      @click="$router.push({ path: '/ME/wallet', query: { id: 'deposit' } })"
    /><span class="fs_16 fw-bolder">{{ balance }}</span>
    <img
      src="@/assets/img/main/one_click_recycling.png"
      class="ms-2"
      style="width: 30px; height: 30px; cursor: pointer"
      @click="allgameToLobby"
    />
  </div>
</template>
<script>
import Cookies from "js-cookie";
export default {
  name: "balance",
  props: { whopage: String },
  data() {
    return {
      code: Cookies.get("code"),
      balance: 0,
    };
  },
  components: {},
  computed: {},
  methods: {
    allgameToLobby: function () {
      let param = {
        action: "allgame_to_LOBBY",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        this.$MSG.API_popmsg(res["balance"], "allgameTolobby", "success");
      });
    },
    getBalance: function () {
      let param = {
        action: "balance",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        this.balance = res["balance"];
      });
    },
  },
  created() {
    if (this.code != null) {
      this.getBalance();
    }
  },
  watch: {},
};
</script>
