<template>
  <div class="container" id="home">
    <div class="row">
      <maintop1 ref="newmesstop" />
      <div
        :class="
          (content_w == 'content4' || content_w == 'content10') && flag_nav
            ? 'main_fix_offset right_ann'
            : (content_w == 'content4' || content_w == 'content10') && !flag_nav
            ? 'main_fix_width right_ann_close'
            : flag_nav
            ? 'main_fix_offset col-xl-10'
            : 'main_fix_width col-xl-12'
        "
      >
        <!-- 电脑板 -->
        <div
          class="row mx-auto my-2 d-xl-flex d-none justify-content-center p-4"
        >
          <balanceview :whopage="'homepc'" ref="getbalance_home" />
          <div class="col-3 text-end mt-5">
            <img
              :src="
                big_head_img_set == '' || big_head_img_set == null
                  ? ''
                  : require('@/assets/img/big_head/' +
                      big_head_img_set +
                      '.png')
              "
              class="big_head_img"
              @click="flag_head = true"
            />
          </div>
          <div class="col-6 mt-3">
            <div class="col-9">
              <label class="me-4 text_gray fw-bold fs_18">{{ userid }}</label>
              <button
                class="border-0 text-white bg_green_btn p-2 w-50"
                @click="
                  this.code != null
                    ? (content_w = 'share')
                    : this.$router.push('/Login')
                "
              >
                <span v-if="this.code == null">{{ $t("home_text1") }}</span>
                <span v-else>{{ $t("home_text2") }}</span>
              </button>
            </div>
            <div class="d-inline-block col-3 mt-3">
              <p class="text-center fs_16 text_gray fw-bolder mb-0">
                {{ vip }}
              </p>
              <p class="text-center fs_12">{{ $t("home_text3") }}</p>
            </div>
            <div class="d-inline-block col-3 mt-3">
              <p class="text-center fs_16 text_gray fw-bolder mb-0">
                {{ follow }}
              </p>
              <p class="text-center fs_12">{{ $t("home_text4") }}</p>
            </div>

            <div class="row">
              <label class="col-9 text-break mt-2"
                >Play and Share.Unlock more fun and prize.</label
              >
            </div>
          </div>
        </div>

        <!-- 手机版 -->
        <div
          class="row mx-auto mb-2 d-xl-none d-flex justify-content-center p-3"
        >
          <maintop2 v-if="this.code == null" />
          <balanceview :whopage="'homemobi'" ref="getbalance_home" />
          <div
            class="row mx-auto"
            :style="this.code == null ? 'margin-top:18%' : ''"
          >
            <div class="col-3 p-0">
              <img
                :src="
                  big_head_img_set == '' || big_head_img_set == null
                    ? ''
                    : require('@/assets/img/big_head/' +
                        big_head_img_set +
                        '.png')
                "
                class="big_head_img"
                @click="flag_head = true"
              />
            </div>
            <div class="col-8 m-auto me-0">
              <label class="col-6 text-center fs_16 text_gray fw-bolder">{{
                vip
              }}</label>
              <label class="col-6 text-center fs_16 text_gray fw-bolder">{{
                follow
              }}</label>
              <label class="col-6 text-center fs_12">{{
                $t("home_text3")
              }}</label>
              <label class="col-6 text-center fs_12">{{
                $t("home_text4")
              }}</label>
              <div class="col-10 text-white mx-auto">
                <button
                  class="border-0 text-white bg_green_btn p-2 w-100"
                  @click="
                    this.code != null
                      ? (content_w = 'share')
                      : this.$router.push('/Login')
                  "
                >
                  <span v-if="this.code == null">{{ $t("home_text1") }}</span>
                  <span v-else>{{ $t("home_text2") }}</span>
                </button>
              </div>
            </div>
            <p class="mt-3 fw-bold">{{ userid }}</p>
            <label class="col-12 text-break"
              >Play and Share.Unlock more fun and prize.</label
            >
          </div>

          <Footer />
        </div>

        <hr class="m-0" />

        <div>
          <div class="game_list" v-show="content_w != 'share'">
            <div class="list_card tab-title p-2 mx-xl-auto">
              <div
                class="list_name col-4 main_img"
                @click="content_w = 'content0'"
                v-if="code == null"
                :class="{ list_hover: content_w == 'content0' }"
              >
                <img
                  src="@/assets/img/main/mini.png"
                  :title="$t('text_free').toUpperCase()"
                />
              </div>

              <div
                class="list_name col-4 main_img"
                @click="(content_w = 'content1'), (type = 'T1')"
                :class="{ list_hover: content_w == 'content1' }"
              >
                <img
                  src="@/assets/img/main/ic2_minigame.png"
                  :title="$t('text_mini').toUpperCase()"
                />
              </div>

              <div
                class="list_name col-4 main_img"
                @click="(content_w = 'content2'), (type = 'slot')"
                :class="{ list_hover: content_w == 'content2' }"
              >
                <img
                  src="@/assets/img/main/ic2_hot.png"
                  :title="$t('text_hot').toUpperCase()"
                />
              </div>

              <div
                class="list_name col-4 main_img"
                @click="content_w = 'content3'"
                :class="{ list_hover: content_w == 'content3' }"
              >
                <img
                  src="@/assets/img/main/ic2_pro.png"
                  :title="$t('faq_5').toUpperCase()"
                />
              </div>

              <div
                class="list_name col-4 main_img"
                @click="content_w = 'content5'"
                :class="{ list_hover: content_w == 'content5' }"
              >
                <img
                  src="@/assets/img/main/1420338.png"
                  :title="$t('text_rank').toUpperCase()"
                />
              </div>
              <div
                class="list_name col-4 main_img"
                @click="(content_w = 'content6'), (type = 'shoot')"
                :class="{ list_hover: content_w == 'content6' }"
              >
                <img
                  src="@/assets/img/main/shoot.png"
                  :title="$t('text_shoot').toUpperCase()"
                />
              </div>
              <div
                class="list_name col-4 main_img"
                @click="(content_w = 'content7'), (type = 'mini')"
                :class="{ list_hover: content_w == 'content7' }"
              >
                <img
                  src="@/assets/img/main/minigame.png"
                  :title="$t('text_mini').toUpperCase()"
                />
              </div>
              <div
                class="list_name col-4 main_img"
                @click="(content_w = 'content8'), (type = 'virtual')"
                :class="{ list_hover: content_w == 'content8' }"
              >
                <img
                  src="@/assets/img/main/virtual.png"
                  :title="$t('text_virtual').toUpperCase()"
                />
              </div>
              <div
                class="list_name col-4 main_img"
                @click="content_w = 'content9'"
                :class="{ list_hover: content_w == 'content9' }"
              >
                <img
                  src="@/assets/img/main/ic2_free.png"
                  :title="$t('text_free').toUpperCase()"
                />
              </div>
              <div
                class="list_name col-4 main_img"
                @click="(content_w = 'content4'), (type = 'casino')"
                v-if="(this.code != null && this.utype != 0) || Id == 'igaming'"
                :class="{ list_hover: content_w == 'content4' }"
              >
                <img
                  src="@/assets/img/main/ic2_slot.png"
                  :title="$t('text_igaming').toUpperCase()"
                />
              </div>
              <div
                class="list_name col-4 main_img"
                @click="content_w = 'content10'"
                :class="{ list_hover: content_w == 'content10' }"
                v-if="code != null"
              >
                <img
                  src="@/assets/img/main/invest.png"
                  :title="$t('text_invest').toUpperCase()"
                />
              </div>
            </div>
          </div>

          <div v-show="content_w == 'content0'" class="mobi_margin">
            <freegame />
          </div>

          <div v-show="content_w == 'content1'" class="mobi_margin">
            <div class="content_img">
              <img
                v-for="l in list"
                :key="l"
                class="col-4"
                v-lazy="'http://gameweb.xkzkji.com/' + l.image"
                @click="
                  game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)
                "
              />
            </div>
          </div>

          <div v-show="content_w == 'content2'" class="mobi_margin">
            <div class="live_casino tab-inner">
              <div class="live_casino_card">
                <div class="game_slider_card">
                  <div class="col-12 game_logo_img fw-bolder my-3">
                    {{ $t("home_text5") }}
                  </div>
                  <div
                    v-for="l in content2_slot"
                    :key="l"
                    class="filter_card"
                    @click="
                      game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)
                    "
                  >
                    <div
                      class="filter_card1"
                      v-lazy:background-image="
                        'http://gameweb.xkzkji.com/' + l.image
                      "
                    ></div>
                    <div class="mask position-absolute top-0 start-0"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-show="content_w == 'content3'" class="mobi_margin">
            <promotionview :index="getanumber()"></promotionview>
          </div>

          <div v-if="content_w == 'content4'" class="mobi_margin">
            <div class="row mx-auto">
              <banner />
              <div class="live_casino tab-inner">
                <div class="live_casino_card">
                  <div class="game_slider_card">
                    <div class="col-12 game_logo_img fw-bolder my-3">
                      {{ $t("home_text6") }}
                    </div>
                    <div
                      v-for="l in list"
                      :key="l"
                      class="filter_card"
                      @click="
                        game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)
                      "
                    >
                      <div
                        class="filter_card1"
                        v-lazy:background-image="
                          'http://gameweb.xkzkji.com/' + l.image
                        "
                      ></div>
                      <div class="mask position-absolute top-0 start-0"></div>
                    </div>
                    <div class="col-12">
                      <img
                        src="@/assets/img/game/all/live_bg.png"
                        :class="css_list"
                        @click="game('web', 'casino', '', 'BG', 'casino')"
                      />
                      <img
                        src="@/assets/img/game/all/live_yeebet.png"
                        :class="css_list"
                        @click="game('web', 'casino', '', 'YB', 'casino')"
                      />
                      <img
                        src="@/assets/img/game/all/live_wm.png"
                        :class="css_list"
                        @click="game('web', 'casino', '0', 'WM', 'casino')"
                      />
                      <img
                        src="@/assets/img/game/all/live_evo.png"
                        :class="css_list"
                        @click="game('web', 'casino', '', 'EVO', 'casino')"
                      />
                      <!-- <img
                        src="@/assets/img/game/all/live_xg.png"
                        :class="css_list"
                        @click="game('web', 'casino', 'xg006', 'XG', 'casino')"
                      /> -->
                      <img
                        src="@/assets/img/game/all/live_ag.png"
                        :class="css_list"
                        @click="game('', '1', '0', 'AG', 'casino')"
                      />
                      <img
                        src="@/assets/img/game/all/live_pp.png"
                        :class="css_list"
                        @click="game('web', 'casino', '101', 'PP', 'casino')"
                      />
                    </div>
                    <div class="col-12 game_logo_img fw-bolder my-3">
                      {{ $t("home_text7") }}
                    </div>
                    <div class="col-12">
                      <img
                        v-for="l in slot_game"
                        :key="l"
                        :src="
                          require('@/assets/img/game/all/slot_' + l + '.png')
                        "
                        :class="css_list"
                        @click="set_gametype(l.toUpperCase())"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <img
                src="@/assets/img/main/sponser_cbf.jpg"
                class="right_img_ann offset-2 d-none col-2"
              />
            </div>
          </div>

          <div v-if="content_w == 'content5'" class="mobi_margin">
            <rankview></rankview>
          </div>

          <div v-show="content_w == 'content6'" class="mobi_margin">
            <div class="content_img">
              <img
                v-for="l in list"
                :key="l"
                class="col-xl-3 col-4"
                v-lazy="l.bannerName"
                @click="
                  game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)
                "
              />
            </div>
          </div>

          <div v-show="content_w == 'content7'" class="mobi_margin">
            <div class="content_img">
              <img
                v-for="l in list"
                :key="l"
                class="col-xl-3 col-4"
                v-lazy="l.bannerName"
                @click="
                  game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)
                "
              />
            </div>
          </div>

          <div v-show="content_w == 'content8'" class="mobi_margin">
            <div class="content_img">
              <img
                v-for="l in list"
                :key="l"
                class="col-xl-4 col-6"
                v-lazy="l.bannerName"
                @click="
                  game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)
                "
              />
            </div>
          </div>

          <div v-show="content_w == 'content9'" class="mobi_margin">
            <freegame9 />
          </div>

          <div
            v-if="content_w == 'content10' && code != null"
            class="mobi_margin"
          >
            <investview />
            <!-- <financeview /> -->
            <giftview v-if="false" />
            <img
              src="@/assets/img/main/sponser_cbf.jpg"
              class="right_img_ann offset-2 d-none col-2"
            />
          </div>

          <div v-show="content_w == 'share'" class="mobi_margin">
            <share
              :shareurl="shareurl"
              :share_more_content="share_more_content"
            />
          </div>
        </div>

        <dw_rank v-show="content_w != 'content5'" />
        <footertwo />
      </div>
    </div>
    <prewindow></prewindow>
    <bighead
      @flag_val="flag_big_fun"
      v-if="flag_head && code != null"
    ></bighead>
    <newWindow
      :URL="newWindowurl"
      @iframe_close="iframeclosed"
      v-show="this.newWindowurl != ''"
    ></newWindow>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import maintop1 from "./Main_top1.vue";
import maintop2 from "./Main_top2.vue";
import Footer from "./Home/Footer.vue";
import banner from "./Home/Banner.vue";
import promotionview from "./Promotion.vue";
import rankview from "./rank.vue";
import balanceview from "./balance.vue";
import footertwo from "./Home/footer_two.vue";
import Prewindow from "./Home/prewindow.vue";
import bighead from "./Home/big_head_img.vue";
import share from "./share.vue";
import investview from "./invest.vue";
// import financeview from "./finance.vue";
import newWindow from "./iframe.vue";
import giftview from "./gift.vue";
import dw_rank from "./dw_rank.vue";
import freegame from "./free_game.vue";
import freegame9 from "./free_game_9.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Home",
  data() {
    return {
      content_w: "content0",
      code: Cookies.get("code"),
      userid: "GAMEWEB",
      device: "",
      gametype: "",
      gamekind: "",
      gameid: "",
      mode: "",
      type: "T1",
      css_list: "col-lg-4 col-6 mb-2 px-2",
      utype: localStorage.getItem("utype"),
      flag_head: false,
      vip: 0,
      follow: 0,
      shareurl: "",
      share_more_content: [],
      big_head_img_set: localStorage.getItem("big_logo"),
      newWindowurl: "",
      slot_game: [
        "pg",
        "fachai",
        "jili",
        "ps",
        "ka",
        "funta",
        "rich",
        "pp",
        "CQ9",
      ],
    };
  },
  components: {
    Footer,
    maintop1,
    maintop2,
    banner,
    promotionview,
    rankview,
    balanceview,
    footertwo,
    Prewindow,
    bighead,
    share,
    investview,
    // financeview,
    newWindow,
    giftview,
    dw_rank,
    freegame,
    freegame9,
  },
  computed: {
    ...mapGetters(["list", "flag_nav"]),
    Id: function () {
      if (this.$route.params.id != null) return this.$route.params.id;
      else return "";
    },
    content2_slot: function () {
      if (this.content_w == "content2" && this.list)
        return this.list.slice(0, 40);
      else return [];
    },
  },
  methods: {
    ...mapActions(["getGameList"]),
    gamelist: function (t) {
      var action_val =
        t != "shoot" && t != "mini" && t != "virtual" ? "GameLobby" : "";
      let param = {
        action: action_val,
        body: {
          gametype: t,
          type: "all",
        },
      };
      this.getGameList(param);
    },
    game: function (device, mode, id, type, kind) {
      if (this.code == null || this.code == "") {
        this.$router.push("Login");
        return;
      } else {
        if (id == "Coming Soon") {
          alert(this.$t("text_coming"));
        } else if (id == "GameLobby") {
          localStorage.setItem("g", type);
          this.$router.push({ name: "Game" });
        } else {
          let param = {
            action: "stage_balance",
            body: {
              uid: localStorage.getItem("uid"),
              ip: localStorage.getItem("ip"),
              session_code: Cookies.get("code"),
              device: device,
              gametype: type,
              gamekind: kind,
              gameid: id,
              mode: mode,
            },
          };
          this.$store.dispatch("getpost", param).then((res) => {
            this.newWindowurl = res;
          });
        }
      }
    },
    hide_nav: function () {
      this.$store.commit("flagnav", !this.flag_nav);
    },
    getanumber: function () {
      return Math.random();
    },
    set_gametype: function (e) {
      localStorage.setItem("g", e);
      this.$router.push("/slot");
    },
    flag_big_fun: function (e, imgval) {
      this.flag_head = e;
      localStorage.setItem("big_logo", imgval);
      this.big_head_img_set = imgval;
    },
    change_con_ig: function () {
      this.content_w = "content4";
      this.type = "casino";
    },
    vip_follow: function (txt) {
      let param = {
        action: "vip_follows",
        body: {
          uid: txt,
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        if (parseFloat(res["vip"]) / 1000000000 > 1)
          this.vip = parseFloat(res["vip"]) / 1000000000 + "B";
        else if (parseFloat(res["vip"]) / 1000000 > 1)
          this.vip = parseFloat(res["vip"]) / 1000000 + "M";
        else if (parseFloat(res["vip"]) / 1000 > 1)
          this.vip = parseFloat(res["vip"]) / 1000 + "K";
        else this.vip = res["vip"];

        if (parseFloat(res["follow"]) / 1000000000 > 1)
          this.follow = parseFloat(res["follow"]) / 1000000000 + "B";
        else if (parseFloat(res["follow"]) / 1000000 > 1)
          this.follow = parseFloat(res["follow"]) / 1000000 + "M";
        else if (parseFloat(res["follow"]) / 1000 > 1)
          this.follow = parseFloat(res["follow"]) / 1000 + "K";
        else this.follow = res["follow"];
      });
    },
    share: function () {
      let param = {
        action: "share_info",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
          domain: window.location.host,
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        var status = res.status;
        if (status == "success") {
          this.shareurl = res.url;
          this.share_more_content = res.share_more_content;
        }
      });
    },
    nologin_bigimg: function () {
      let param = {
        action: "Member_unlogin",
        body: {
          uid: this.userid,
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        if (res.status == "success") {
          this.big_head_img_set = res.logo;
        }
      });
    },
    iframeclosed(val) {
      this.newWindowurl = val;
    },
  },
  created() {
    if (this.code != null) {
      this.userid = localStorage.getItem("uid");
      this.content_w = "content1";
      this.share();
    } else {
      this.userid =
        localStorage.getItem("introducer") == null ||
        localStorage.getItem("introducer") == ""
          ? "GAMEWEB"
          : localStorage.getItem("introducer");

      if (this.userid != "GAMEWEB") this.nologin_bigimg();
      else this.big_head_img_set = "logo_big";
    }
    this.vip_follow(this.userid);
    this.type = "T1";
    this.gamelist(this.type);
    if (this.Id == "igaming") this.change_con_ig();
  },
  watch: {
    type: function (e) {
      this.gamelist(e);
    },
    newWindowurl() {
      if (this.newWindowurl == "") {
        this.$refs.getbalance_home.getBalance();
        this.$refs.newmesstop.getnewmess();
      }
    },
  },
  mounted() {},
};
</script>

<style scoped>
#login #home .offset-xl-2 {
  margin-left: 0;
}

#login #home .navbar,
#login #home .collaspe {
  display: none;
}
</style>
